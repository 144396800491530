<template>
    <div v-if="isPC" class="pcBg">
    <el-form
      ref="loginForm"
      label-position="left"
      label-width="0px"
      class="login-form"
    >
      <p class="loginPc">获客宝智能获客平台</p>
      <el-form-item prop="phone" style="margin-bottom: 0.3542rem">
        <el-input
          v-model="phone"
          type="text"
          auto-complete="off"
          placeholder="手机号"
          class="clear-number-input"
          @input="
            phone =
              phone.length > 11
                ? phone.slice(0, 11)
                : phone
          "
        >
          <i
            slot="prefix"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            "
          >
            <img
              class="input-icon"
              style="width: 13px; height: 14px"
              src="../assets/images/phone.png"
              alt=""
            />
          </i>
          <!-- <svg-icon
            slot="prefix"
            icon-class="user"
            class="el-input__icon input-icon"
          /> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="code" style="margin-bottom: 0.3542rem">
        <el-input
          v-model="pwd"
          auto-complete="off"
          placeholder="验证码"
          @keyup.enter.native="handleLogin"
        >
          <i
            slot="prefix"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            "
          >
            <img
              class="input-icon"
              style="width: 13px; height: 14px"
              src="../assets/images/psd.png"
              alt=""
            />
          </i>
          <!-- <svg-icon
            slot="prefix"
            icon-class="validCode"
            class="el-input__icon input-icon"
          /> -->
          <div slot="suffix" class="code-container">
            <el-button
              v-show="isReady"
              size="small"
              style="background-color: #498bf0; color: #fff"
              @click="yzm1"
              >获取验证码</el-button
            >
            <el-button v-show="!isReady" size="small" type="info"
              >{{ yzm }}</el-button
            >
          </div>
        </el-input>
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button
          size="medium"
          style="
            width: 100%;
            padding: 15px 20px;
            background-color: #498bf0;
            color: #fff;
          "
          @click.native.prevent="sign()"
        >
          <span>登 录</span>
        </el-button>
      </el-form-item>
    </el-form>
    </div>
  <div class="login" v-else>
    <div class="login-content">
      <div class="img"></div>

      <h1>获客宝智能获客平台</h1>

      <p class="edition">V1.0.0</p>

      <p class="mode">账号登录</p>

      <el-input
        v-model="phone"
        placeholder="请输入账号"
        maxlength="11"
      ></el-input>

      <div class="yzm">
        <el-input
          v-model="pwd"
          placeholder="请输入验证码"
          show-password
          @keyup.enter.native="sign"
        ></el-input>

        <el-button @click="yzm1" :disabled="disabled">{{ yzm }}</el-button>
      </div>

      <el-button type="primary" class="login" shape="circle" @click="sign()"
        >登录</el-button
      >
    </div>
  </div>
</template>



<script>
import { post_login, find_user, user_login } from "../api/login.js";

export default {
  name: "login",

  data() {
    return {
      
      isReady: true,
      phone: "", //输入的手机号

      pwd: "", //输入的验证码

      yzm: "获取验证码",

      disabled: false,

      time: 60,

      show: false,

      isPC: true,

      disableLogin: false,

      showPhone: false,

      showCode: false,
    };
  },
  watch: {
    phone(newVal) {
      if (newVal) {
        this.showPhone = true;
        if (this.pwd) {
          this.disableLogin = true;
        }
      } else {
        this.showPhone = false;
      }
    },
    pwd(newVal) {
      if (newVal) {
        this.showCode = true;
        if (this.phone) {
          this.disableLogin = true;
        }
      } else {
        this.showCode = false;
      }
    },
  },
  methods: {
    //验证码

    yzm1() {
      console.log(this.phone);
      post_login({ phone: this.phone }).then((res) => {
        if (res.code == 200) {
          this.isReady = false
          this.time = 60;
          this.timer();
          this.$message({
            message: "获取验证码成功",
            type: "success",
          });
        }
      });
    },

    //验证码

    timer() {
      if (this.time > 0) {
        this.disabled = true;

        this.time--;

        this.yzm = this.time + "秒";

        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;

        this.yzm = "重新获取";

        this.disabled = false;
        
        this.isReady = true;
      }
    },

    //登录事件

    sign() {
      sessionStorage.setItem("phone", this.phone);

      if (this.phone === "") {
        this.$message.error("请输入手机号");
      } else if (this.pwd === "") {
        this.$message.error("请输入验证码");
      } else {
        user_login({ phone: this.phone, code: this.pwd }).then((res) => {
          if (res.code == 200) {
            sessionStorage.setItem("token", res.data);
            this.$message({
              message: res.msg,
              type: "success",
            });
            console.log(res.data);
            find_user().then((resc) => {
              if (resc.data.perms == "user") {
                sessionStorage.setItem("Permission", "/user");
                sessionStorage.setItem("perms", resc.data.perms);
              } else if(resc.data.perms == "system") {
                  sessionStorage.setItem("perms","system");
                  sessionStorage.setItem("Permission", "/SubplanData");
              }

              sessionStorage.setItem("userName", resc.data.userName);

              // sessionStorage.setItem("token", res.data);

              sessionStorage.setItem("code", 0);

              location.reload();
            });
          }
        });
      }
    },
  },
  created() {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    const bIsMidp = sUserAgent.match(/midp/i) == "midp";
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    const bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    const bIsAndroid = sUserAgent.match(/android/i) == "android";
    const bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    const bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      this.isPC = false;
    } else {
      this.isPC = true;
    }
  },
};
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
//弹框颜色

::v-deep .ivu-message-notice-content {
  background: #ffb8bb !important;

  color: #e51c23 !important;
}

.login {
  display: flex;

  justify-content: center;

  align-items: center;

  height: 100vh;

  .login-content {
    width: 380px;

    height: 550px;

    text-align: center;

    box-shadow: 0px 0px 16px 1px rgba(41, 198, 243, 100);

    border: 1px solid rgba(255, 0, 0, 0);

    padding: 18px 25px 0 25px;

    .img {
      width: 80px;

      height: 80px;

      background: url("../../public/images/logo.png");

      background-size: 100% 100%;

      box-shadow: 0px 2px 6px 0px rgba(79, 249, 255, 100);

      border-radius: 50%;

      margin: 14px auto;
    }

    h1 {
      line-height: 41px;

      color: rgba(23, 120, 252, 100);

      font-size: 28px;

      font-family: Roboto;
    }

    .edition {
      line-height: 23px;

      color: rgba(136, 136, 136, 100);

      font-size: 16px;

      font-family: Roboto;
    }

    .mode {
      line-height: 29px;

      opacity: 0.5;

      color: rgba(38, 50, 56, 100);

      font-size: 20px;

      font-family: Roboto;
    }

    .yzm {
      position: relative;

      ::v-deep button {
        position: absolute;

        top: 50%;

        right: 10px;

        transform: translate(0, -50%);
      }
    }

    ::v-deep .el-input {
      padding-bottom: 15px;

      padding-top: 15px;

      height: auto;
    }

    ::v-deep .el-input:focus {
      outline: 0;

      box-shadow: 0 5px 5px -5px rgba(45, 140, 240, 0.2);
    }

    .login {
      margin: 50px auto 0 auto;

      width: 300px;

      height: 50px;

      opacity: 0.56;

      background-color: rgba(23, 120, 252, 100);

      color: rgba(255, 255, 255, 100);

      font-size: 20px;

      box-shadow: 0px 2px 6px 0px rgba(23, 120, 252, 100);

      font-family: Microsoft Yahei;
    }
  }
}

.el-button {
  border: 0px solid #dcdfe6 !important;
}

.pcBg {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: url("../assets/images//login.png") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  
}

/deep/ .el-input__inner {
  // height: 0.3958rem;
  // border: 0;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .pcBg .el-input__inner {
  height: 0.3958rem !important;
  border: 0 !important;
  font-size: 21px;

}

/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 2.7083rem;
  height: 3.2083rem;
  padding: 0.1302rem 0.1302rem 0.026rem 0.1302rem;
  margin-left: 6.0729rem;

  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }

  .clear-number-input.el-input::-webkit-outer-spin-button,
  .clear-number-input.el-input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none !important;
  }

  .clear-number-input.el-input input[type="number"]::-webkit-outer-spin-button,
  .clear-number-input.el-input input[type="number"]::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none !important;
  }

  .clear-number-input.el-input {
    -moz-appearance: textfield;
  }

  .clear-number-input.el-input input[type="number"] {
    -moz-appearance: textfield;
  }
}

.loginPc {
  height: 0.3281rem;
  margin-top: 0.3281rem;
  margin-bottom: 0.3438rem;
  font-size: 0.1667rem;
  color: #333333;
  font-weight: 500;
}
</style>

